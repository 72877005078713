import dwellHome from './assets/projects/dwell-together/screenshots/dwell-home.png';
import n2nSearch from './assets/projects/n2n/screenshots/n2n-search-results.png';
import portfolioHome from './assets/projects/portfolio/web-home.png';
import mcAll from './assets/projects/missed-connections/mc-all-posts.png';

export const contacts = [
  {
    link: 'https://www.github.com/winterlamon/',
    iconPrefix: 'fab',
    iconName: 'github',
    altText: 'GitHub',
  },
  {
    link: 'https://www.linkedin.com/in/winterlamon/',
    iconPrefix: 'fab',
    iconName: 'linkedin',
    altText: 'LinkedIn',
  },
  // {
  //   link: 'https://medium.com/@winter.lamon',
  //   iconPrefix: 'fab',
  //   iconName: 'medium',
  //   altText: 'Medium'
  // },
  // {
  //   link: 'https://twitter.com/winterlamon',
  //   iconPrefix: 'fab',
  //   iconName: 'twitter',
  //   altText: 'Twitter'
  // }
];

export const projects = [
  {
    title: "D'well Together",
    shortDesc: 'A communal living organizer',
    technologies: [
      'Ruby on Rails',
      'React',
      'Redux',
      'Material UI',
      'HTML5',
      'CSS',
    ],
    mainImage: [dwellHome, "D'well Together user interface"],
    images: [],
    demoLink: '',
    githubLinks: [
      {
        url: 'https://github.com/winterlamon/dwell-together-frontend-react',
        label: 'Front-end Repo',
      },
      {
        url: 'https://github.com/winterlamon/dwell-together-api',
        label: 'Back-end Repo',
      },
    ],
  },
  {
    title: 'New2Neighborhood',
    shortDesc: 'A neighborhood restaurant & bar finder',
    technologies: [
      'Ruby on Rails',
      'React',
      'Redux',
      'Material UI',
      'HTML5',
      'CSS',
    ],
    mainImage: [n2nSearch, 'New2Neighborhood user interface'],
    images: [],
    demoLink: '',
    githubLinks: [
      {
        url: 'https://github.com/winterlamon/new2neighborhood-frontend',
        label: 'Front-end Repo',
      },
      {
        url: 'https://github.com/winterlamon/new2neighborhood-api',
        label: 'Back-end Repo',
      },
    ],
  },
  {
    title: 'winterlamon.com',
    shortDesc: 'Portfolio site for Winter LaMon',
    technologies: ['React', 'Bootstrap', 'HTML5', 'CSS'],
    mainImage: [portfolioHome, 'winterlamon.com user interface'],
    images: [],
    demoLink: '',
    githubLinks: [],
  },
  {
    title: 'Missed Connections',
    shortDesc: 'A Craigslist replica for a performance piece',
    technologies: ['React', 'HTML5', 'CSS'],
    mainImage: [mcAll, 'Missed Connections user interface'],
    images: [],
    demoLink: '',
    githubLinks: [],
  },
  // {
  //   title: 'Presently',
  //   shortDesc: 'An Etsy-based gift exchange',
  //   technologies: ['Ruby on Rails', 'ERB', 'HTML5', 'CSS'],
  //   mainImage: [', 'Presently user interface'],
  //   images: [],
  //   demoLink: ',
  //   githubLinks: [
  //     {
  //       url: 'https://github.com/winterlamon/presently-v2',
  //       label: 'Repo'
  //     }
  //   ]
  // }
];
