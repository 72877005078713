import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

export const Contact = () => {
  const [state, handleSubmit] = useForm('xevvjyjm');

  const renderContactForm = () => (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Name</label>
      <input
        id="name"
        type="name"
        name="name"
        placeholder="Katherine Johnson"
        className="input"
        required
      />

      <label htmlFor="email">Email</label>
      <input
        id="email"
        type="email"
        name="_replyto"
        placeholder="katherine@email.com"
        className="input"
        required
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />

      <label htmlFor="subject">Subject</label>
      <input
        id="subject"
        type="text"
        name="_subject"
        className="input"
        placeholder="Let's chat"
        required
      />

      <input
        id="phone"
        type="hidden"
        name="_gotcha"
        placeholder="000-000-0000"
      />

      <label htmlFor="message">Message</label>
      <textarea
        id="message"
        name="message"
        placeholder="Do you have time for a quick chat?"
        rows={3}
        className="input"
        required
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />

      <button
        type="submit"
        disabled={state.submitting}
        className="mt-2 mb-5-sm-down cta-btn"
      >
        Send message
      </button>
    </form>
  );

  const renderSuccessMessage = () => (
    <div className="mt-6">
      <p className="font-bold">Your message was sent. 🚀</p>
      <p>Thanks! I'll get back to you shortly.</p>
    </div>
  );

  return (
    <section id="contact" className="px-6 py-20 flex justify-center bg-seafoam">
      <div className="container">
        <h2 className="heading text-white">Get in touch!</h2>
        <div className="row pt-4 pb-5">
          <p className="w-full md:w-3/4">
            If you want to chat about a job opportunity, ask a question, share
            your favorite dad joke, or if you just want to shoot the breeze, I'd
            love to hear from you.
          </p>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6" role="region" aria-live="polite">
            {state.succeeded ? renderSuccessMessage() : renderContactForm()}
          </div>
        </div>
      </div>
    </section>
  );
};
