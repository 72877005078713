import React from 'react';

export const Hero = () => (
  <section
    id="hero"
    className="h-screen bg-gradient-to-b from-dark-purple from-0% via-bondi-blue via-65% to-seafoam to-100% py-40 text-white"
  >
    <div className="h-full m-auto px-6 container flex flex-col justify-center items-center">
      <div className="text-center">
        <h1 className="heading text-7xl md:text-8xl lg:text-9xl">
          Winter LaMon
        </h1>
        <p className="text-black font-bold tracking-wide text-3xl mt-6 mb-32">
          Front-end Developer
        </p>
      </div>
      <div>
        <a href="#contact" className="cta-btn">
          Contact me
        </a>
      </div>
    </div>
  </section>
);
