import React from 'react';

export const Footer = () => {
  return (
    <footer
      id="footer"
      className="px-6 py-3 bg-black text-white flex justify-center"
    >
      <p>
        © 2018 - {new Date().getFullYear()} Winter LaMon. All rights reserved.
      </p>
    </footer>
  );
};
