import React from 'react';
import profileImg from '../assets/winter-round-1.png';

export const About = () => {
  const amexLink = (
    <a
      href="https://www.americanexpress.com"
      className="text-dark-purple underline hover:no-underline focus:no-underline"
    >
      American Express
    </a>
  );
  const freshlyLink = (
    <a
      href="https://www.freshly.com"
      className="text-dark-purple underline hover:no-underline focus:no-underline"
    >
      Freshly
    </a>
  );
  const shopifyLink = (
    <a
      href="https://www.shopify.com"
      className="text-dark-purple underline hover:no-underline focus:no-underline"
    >
      Shopify
    </a>
  );

  return (
    <section
      id="about"
      className="px-6 py-20 lg:pb-32 flex justify-center bg-gradient-to-b from-seafoam to-white to-40%"
    >
      <div className="container flex flex-col gap-10 lg:flex-row lg:gap-20 justify-center items-center">
        <div className="w-1/3 flex justify-center items-center min-w-fit">
          <img src={profileImg} alt="Winter LaMon" className="h-5/6 drop-shadow-xl" />
        </div>

        <div className="w-full lg:w-2/3">
          <div className="pb-4">
            <h2 className="heading text-lavender">Hello, I'm&nbsp;Winter.</h2>
          </div>
          <div className="space-y-5">
            <p>
              I’m a front-end developer, web accessibility advocate, and a dad
              joke aficionado who’s enthusiastic about diversity and inclusion,
              empathy, and contributing to the greater good. Two of my core
              beliefs: there is no innovation without representation, and the
              internet should be for
              <em> everyone</em>.
            </p>
            <p>
              Prior to making my way into web development, I worked for various
              non-profits—from a music and empowerment camp for girls and
              gender-expansive youth to a shelter for unhoused LGBTQIA+
              youth—before stumbling into my first dance with the tech industry
              managing operations (and wearing a lot of other hats) at a startup
              that sought to maintain personal connection in a time of all
              things digital. Since then, my fingers have can-can-ed and
              shuffled across keyboards building marketing experiences and
              products for {amexLink}, {freshlyLink}, and {shopifyLink}.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
