import React from 'react';

import { Navbar } from './Navbar';
import { Hero } from './Hero';
import { About } from './About';
import { Contact } from './Contact';
import { Footer } from './Footer';

export const Home = () => {
  return (
    <>
      <a className="sr-only focus:not-sr-only" href="#main-content">
        Skip to main content
      </a>
      <div>
        <Navbar />
        <main id="main-content">
          <Hero />
          <About />
          <Contact />
        </main>
        <Footer />
      </div>
    </>
  );
};
