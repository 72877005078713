import React from 'react';

import { Home } from './layout/Home';
import './index.css';

function Root() {
  return (
    <>
      <Home />
    </>
  );
}

export default Root;
