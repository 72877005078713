import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

import { contacts } from '../data';

export const Navbar = () => {
  return (
    <nav
      id="navbar"
      className="px-6 py-2 flex bg-black text-white justify-between fixed w-full z-50"
    >
      <a
        href="#hero"
        className="text-white no-underline flex items-center"
        aria-label="Home"
      >
        <strong>{'< wl >'}</strong>
      </a>
      <div className="justify-end">
        <div className="space-x-4 flex justify-center">
          {contacts.map((contact) => {
            const { link, altText, iconName, iconPrefix } = contact;
            return (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:bg-seafoam rounded-md"
                aria-label={altText}
                key={altText}
              >
                <FontAwesomeIcon
                  icon={[iconPrefix as IconPrefix, iconName as IconName]}
                  size="2x"
                  className="p-1 hover:text-black"
                  aria-hidden="true"
                  title={altText}
                />
              </a>
            );
          })}
        </div>
      </div>
    </nav>
  );
};
